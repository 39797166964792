.autocomplete-suggestions 
    box-shadow 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0,0,0,0.08) 
    background #FFF 
    overflow auto
    padding 0
    max-width: 100%
    border-radius: 10px
    
.autocomplete-suggestion 
    padding 0 
    white-space nowrap 
    overflow hidden
    cursor pointer
    font-weight 400
    border-bottom 1px solid rgba(0, 0, 0, 0.1)
    .sub-title
        font-size: 13px
        color: #9b999f
    .icon
        width 130px
        display inline-block
        padding 16px 2px
        vertical-align middle
        color #FFF
        text-align center
        margin-top: -2%;
        &.aeropuerto
            background-color #f60c5b
            .rombo
                background: #f60c5b
        &.ciudad, &.especial, &.resaltada
            background-color #e7e7e7
            color #00ade2
            .rombo
                background: #e7e7e7
        &.domicilios
            background-color #ffb22a
            .rombo
                background: #ffb22a
    .rombo
        position: relative
        height: 20px
        width: 20px
        left: 90%
        transform: rotate(45deg)
        top: 20px
        z-index: -1
    .text
        width calc(100% - 165px)
        display inline-block
        vertical-align middle
        padding 2px
        margin-left: 2%
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        .title
            font-size: 14px !important
            font-weight: bold !important
            @media only screen and (min-width: 1200px)
                font-size: 16px !important
            @media only screen and (min-width: 1300px)
                font-size: 17px !important
            @media only screen and (min-width: 1400px)
                font-size: 18px !important
        span
            display block
            &:first-child
                color $azul-oscur

.autocomplete-suggestion:last-child
    border-bottom none
    
.autocomplete-selected 
    background rgba(0, 0, 0, 0.05)
    border-bottom 1px solid rgba(0, 0, 0, 0.1)
    
.selected-click
    font-weight 600
    
.autocomplete-suggestions strong 
    font-weight 400 
    color #3399FF
    
.autocomplete-group 
    padding 8px
    
.autocomplete-group strong 
    display block 
    

.autocomplete-suggestion.resaltada
    background-color rgba(0, 0, 0, 0.9)
    border-bottom 1px solid rgba(0, 0, 0, 0.9)
    color #FFF
    border-radius 2px

.autocomplete-suggestion.resaltada.autocomplete-selected 
    background-color #000
    color #FFF