#promociones
    background-color: $amarillo
    min-height: 300px
    .nav-tabs
        border-bottom: none
    nav
        position: relative
        top: -37px
        .nav-link
            border-radius: 50% 50% 0 0
        .nav-link.active
            background-color: $amarillo
            border: none
    .imagen-promo
        width: 90%
        object-fit: contain
        height: 220px
        margin-right: 30px
    .btn-success
        font-size: 22px
    .cbotones
        position: absolute
        left: 47.5%
        padding: 30px
        margin-top: 10px
        button
            justify-content: space-evenly
            align-items: center
            width: 28px
            height: 28px
            border-radius: 50%
            display: flex
            cursor: pointer
            padding: 0
            &.prev
                border: 1px solid $azul-oscuro2
                background-color: $amarillo
                color: $azul-oscuro2
            &.next
                border: 1px solid $azul-oscuro2
                background-color: $azul-oscuro2
                color: $amarillo
    .medio
        display: none
        @media only screen and (min-width: 993px)
            display: block
            position: absolute
            text-align: center
            top: 0
            bottom: 0
            left: calc(50% - 60px)
            z-index: 1
            .cicon-trofeo
                margin-top: -8px
                i
                    font-size: 120px
                    span
                        background-color transparent
            .linea
                width: 2px
                display: inline-block
                border: 1px dashed $azul-oscuro2
                position: absolute
                left: calc(50% - 1px)
                top: 140px
                bottom: 10px
    .text-promo
        padding-left: 10%
    .tab-content
        margin-top: -37px