@import 'include/base.sass'
@import 'include/iconos.sass'
@import 'include/promociones.sass'
@import 'include/comentarios.sass'

.webp
    #banner-renting
        background-image: url(/public/img/home/banner-alkirenting.webp)
    #links
        background-image: url(/public/img/home/bg-links.webp)
    #testimonios
        background-image: url('/public/img/home/bg-comentarios-mobile.webp')
        @media only screen and (min-width: 400px)
            background-image: url('/public/img/home/bg_comentarios.webp')
#marcas
    background-image: url(/public/img/home/bg-marcas.webp)
    z-index: 0
    box-shadow: 0px -5px 5px rgba(61, 61, 61, 0.3)
    padding-bottom: 30px
    .button-down
        margin-top: -20px
    .beneficios
        margin-left: 15%
        margin-right: 10%
        p
            margin-bottom: 0px
    .carro-gris
        margin-top: -35%
        @media only screen and (min-width: 1200px)
            margin-top: -33%
        @media only screen and (min-width: 1300px)
            margin-top: -31%
    .aliados
        text-align: center
        margin-top: -8%
        .mejores
            font-size: 18px
            text-align: center
        .items
            display: flex
            align-items: center
            margin: 1.5rem 0
            margin-top: 0px
            img
                width: auto
                max-height: 42px
                max-width: 98px
                object-fit: contain
                margin: 8px

#paginas
    content-visibility: auto
    background: rgb(255,255,255)
    background: linear-gradient(0deg, rgba(255,255,255,1) 81%, rgba(231,230,232,1) 90%)
    .title-paginas
        margin-left: 25%
        margin-right: 25%
        @media only screen and (min-width: 1300px)
            margin-left: 27%
            margin-right: 27%
        @media only screen and (min-width: 1400px)
            margin-left: 30%
            margin-right: 30%
    .text-paginas
        margin-left: 7%
        margin-right: 7%
        line-height: 1.4
    p
        color: $gris
    .card
        cursor: pointer
        margin: 20px
        border-radius: 18px
        position: relative
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3)
        img
            border-top-right-radius: 18px
            border-top-left-radius: 18px
            width: 100%
            height: 200px
            object-fit: cover
        .flag-promo
            height: 100px
            width: 100px
            position: absolute
            background-image: url(/public/img/home/flag_promo.png)
            background-size: contain
            right: 10%
            top: -10px
            padding-top: 20px
            font-size: 18px
            span
                color: $azul-oscuro2
        .card-body
            border-bottom-left-radius: 18px
            border-bottom-right-radius: 18px
            padding: 10px
            height: 90px
        .card-reveal
            display: none
            position: absolute
            width: 100%
            height: 100%
            border-radius: 18px
            top: 0
            left: 0
            background-color: $verde-card
            color: white
            z-index: 2
        .promo-link
            position: absolute
            right: 50%
            transform: translateX(50%)
            bottom: 16px
            padding: 5px 0

        .card-text
            font-size: 18px !important
    .active
        .card-reveal
            display: block !important
#links
    content-visibility: auto
    background-image: url(/public/img/home/bg-links.webp)
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    @media only screen and (min-width: 993px)
        padding-left: 10%
        padding-bottom: 10px
    h2
        font-size: 20px
        color: $amarillo
    ul
        padding-left: 0px
        li
            list-style: inside
            color: white
            font-size: 17px
            a
                color: white
                text-decoration: none
                margin-left: -7px
#faq
    content-visibility: auto
    cursor: pointer
    .f-l
        padding-left: 20%
        @media only screen and (min-width: 1400px)
            padding-left: 30%
    .f-r
        padding-right: 10%
        @media only screen and (min-width: 1400px)
            padding-right: 20%
    .alk
        float: right
        margin-right: 10px
        font-size: 20px
    .faq
        padding: 10px
        padding-top: 15px
        height: 60px
        @media only screen and (min-width: 1300px)
            height: 65px
    .faq[aria-expanded="true"]
        height: auto
    .faq:not(.collapsed)
        .pregunta
            font-weight: bold
#medios
    content-visibility: auto
    margin-bottom: 40px
    padding-left: 10%
    padding-right: 10%
    @media only screen and (min-width: 1500px)
        padding-left: 20%
        padding-right: 20%
    .title-medios
        color: $azul-oscuro2
        h2
            font-size: 35px
        img
            margin-top: 20px
    .box-medio
        //border: solid 1px $azul-oscuro2
        height: 200px
        padding: 20px
        .img-medio
            height: 70px
            img
                object-fit: none
        .desc-medio
            color: $gris
            font-size: 20px
            text-align: center
            line-height: normal
    .carousel-medios
        padding-top: 25px
        a
            text-decoration: none
        .item
            padding: 10px
        .owl-prev
            position: absolute
            top: 30%
            margin-left: -10%
            display: block !important
            border: 0px solid black
            color: $azul-oscuro2
            font-size: 50px
        .owl-next
            position: absolute
            top: 30%
            right: -10%
            display: block !important
            color: $azul-oscuro2
            font-size: 50px
#banner-renting
    content-visibility: auto
    background-image: url(/public/img/home/banner-renting.webp)
    height: 200px
    @media only screen and (min-width: 1300px)
        height: 300px
        background-size: cover
        margin-top: 20px
        margin-left: 10px
        margin-right: 10px
    .contenedor-text
        margin-top: 0px
        @media only screen and (min-width: 1200px)
            margin-top: 20px
        @media only screen and (min-width: 1300px)
            margin-top: 30px
        @media only screen and (min-width: 1400px)
            margin-top: 40px
    h3
        color: $texto
        font-size: 20px
        span
            font-weight: bold
    a
        border-radius: 10px
        font-size: 22px
    .text
        padding-top: 20px
        @media only screen and (min-width: 1200px)
            padding-top: 40px
        @media only screen and (min-width: 1300px)
            padding-top: 60px
        @media only screen and (min-width: 1400px)
            padding-top: 80px
            text-align: center
.subtitle-beneficios
    padding-left: 25%
    padding-right: 25%
    @media only screen and (min-width: 1300px)
        margin-top: 20px
        padding-left: 27%
        padding-right: 27%
    @media only screen and (min-width: 1400px)
        padding-left: 30%
        padding-right: 30%
.nav-tabs
    margin-left: 7%
    .nav-link
        width: 150px
