#testimonios
    background-image: url('/public/img/home/bg-comentarios-mobile.png')
    background-size: cover
    padding-top: 10px
    @media only screen and (min-width: 400px)
        background-image: url('/public/img/home/bg_comentarios.png')
        background-size: contain
    .testimonio
        img
            @media only screen and (min-width: 993px)
                width: 40px
                height: 40px
            @media only screen and (min-width: 1300px)
                width: 50px
                height: 50px
    .cbotones
        .prev
            left: -10%
            color: $azul-oscuro2
            font-size: 40px
        .next
            color: $azul-oscuro2
            font-size: 40px
            right: -10%
    .estrellas
        color: $gris-oscuro
    .ver-mas
        cursor: pointer